import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== Init Fancybox ======================  */
	Fancybox.bind("[data-fancybox]", {});

/* ====================== Semi Sticky Nav ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header").addClass("headerscrolled");
	}

	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;

		if ((prevScrollpos > currentScrollPos)) {
			$('header').addClass('show');
		} else if((prevScrollpos < currentScrollPos)){
			$('header').removeClass('show');
		}
		else {
			$('header').removeClass('show');
		}

		if(currentScrollPos > 120) {
			$("header").addClass("headerscrolled");
		} else {
			$("header").removeClass("headerscrolled show");
		}

		prevScrollpos = currentScrollPos;
	}

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('header nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1024) {
	    $('#menu-main-menu .has-mega-menu').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.mega-menu').toggleClass('active');
	    })

	    $('.mega-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}

/* ====================== Latest Events Mobile Slider ======================  */
	if(windowWidth < 768) {
		const latest_events = new Swiper('.latest-events .swiper', {
			slidesPerView: 1.3,
			spaceBetween: 20,
			centeredSlides: true,
			loop: false,
			pagination: {
				el: ".latest-events .swiper-pagination",
				clickable: true,
			},
		});
	}

/* ====================== Homepage Hero Carousel ======================  */
	const homepage_masthead_carousel = new Swiper('.homepage-masthead .swiper', {
       	slidesPerView: 1,
       	loop: true,
       	effect: "fade",
       	fadeEffect: { crossFade: true },
       	navigation: {
			nextEl: ".homepage-masthead .swiper-button-next",
			prevEl: ".homepage-masthead .swiper-button-prev",
		},
		pagination: {
			el: ".homepage-masthead .swiper-pagination",
			clickable: true,
		},
	});

/* ====================== Feature Carousel ======================  */
	const feature_carousel = new Swiper('.feature-carousel .swiper', {
       	slidesPerView: 1,
       loop: true,
       	effect: "fade",
       	fadeEffect: { crossFade: true },
       	navigation: {
			nextEl: ".feature-carousel .swiper-button-next",
			prevEl: ".feature-carousel .swiper-button-prev",
		},
		pagination: {
			el: ".feature-carousel .swiper-pagination",
			clickable: true,
		},
	});

	$('.feature-carousel .icon:first-of-type').addClass('active');
	$('.feature-carousel .icon').on("click", function () {
		var slideRelated = $(this).attr('slide-related');

		$('.feature-carousel .icon').removeClass('active');
		$(this).addClass('active');
        var slideIndex = $('.feature-carousel .swiper-slide[slide-related=' + slideRelated + ']').attr('data-swiper-slide-index');
        feature_carousel.slideTo(slideIndex);
    });

	feature_carousel.on('activeIndexChange', function() {
		$('.feature-carousel .icon').removeClass('active');
		var activeSlideIndex = feature_carousel.activeIndex;
		var slideRelated = $('.feature-carousel .swiper-slide[data-swiper-slide-index=' + activeSlideIndex + ']').attr('slide-related');
		$('.feature-carousel .icon[slide-related=' + slideRelated + ']').addClass('active');
	});

/* ====================== Latest News Carousel ======================  */
	const latest_news_carousel = new Swiper('.news-carousel', {
		slidesPerView: 1.3,
		spaceBetween: 20,
		//centeredSlides: true,
		loop: true,
       	navigation: {
			nextEl: ".latest-news .swiper-button-next",
			prevEl: ".latest-news .swiper-button-prev",
		},
		breakpoints: {
		768: {
			centeredSlides: false,
	       	slidesPerView: 2.4,
	       	loop: false,
	       	spaceBetween: 30,
        },
        1024: {
	       	slidesPerView: 2.4,
        },
        1280: {
	       	slidesPerView: 3.5,
	       	loop: false,
	       	spaceBetween: 20,
        },
      }
	});

/* ====================== GSAP SAMPLE ANIMATIONS ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 90%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});
});
